@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@400;500;700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,700;1,800&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,300&family=Public+Sans:wght@300;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: 'Montserrat';
  overflow-x: hidden;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style-type: none;
  /* overflow-block: hidden; */
}
.card{
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06)
}
.uploadcare--widget__button{
  background-color: rgba(255, 160, 72, 0.95) !important;
  display: block;
}